import { defineStore } from "pinia";
import { computed, onMounted } from "vue";

import { components as OpenAPI } from "@/autogen/openapi";
import api from "@/lib/api/base";
import { ok } from "@/lib/openapi";
import { LocalStorageKey } from "@/store/local_storage";

import {
  useCreateListUpdateDeleteResource,
  useLocalStorageBackedRef,
} from "./base";
import { useStore as useScaffoldingStore } from "./scaffolding";

type AutomationInput = OpenAPI["schemas"]["AutomationInput"];
type AutomationUpdateInput = OpenAPI["schemas"]["AutomationUpdateInput"] & {
  id: string;
};

export const useStore = defineStore(
  "sequences",
  () => {
    const resource = useCreateListUpdateDeleteResource(
      async (_params: {}, { signal }) => {
        const data = await ok(api.get("/automations", { signal }));

        return data;
      },
      async (input: AutomationInput) => {
        const data = await ok(api.post("/automations", { body: input }));

        return data;
      },
      async (input: AutomationUpdateInput, { signal }) => {
        const { id, ...body } = input;

        const data = await ok(
          api.patch("/automations/{id}", {
            signal,
            params: {
              path: { id },
            },
            body: body,
          })
        );

        return data;
      },
      async ({ id }) => {
        await ok(
          api.delete("/automations/{id}", {
            params: {
              path: { id },
            },
          })
        );
      }
    );

    const groupingMode = useLocalStorageBackedRef<"name" | "trigger">(
      LocalStorageKey.AUTOMATION_GROUPING,
      "name",
      (v) => v,
      (v) => v as "name" | "trigger"
    );

    const scaffoldingStore = useScaffoldingStore();
    const newsletter = computed(() => scaffoldingStore.newsletter);

    onMounted(() => {
      // Only list if `automations` is an enabled feature. This saves us an API request.
      if (newsletter.value?.enabled_features.includes("automations")) {
        resource.list();
      }
    });

    return {
      groupingMode,
      ...resource,
    };
  },
  {
    broadcast: {
      // Unlike `emails`, we can't omit `idToDetailResource' specifically — we don't need to _fetch_ detail resources explicitly,
      // since everything we need is already in the `resource` array. This means sync is a little janky (ie, we do not sync at all), but that's
      // better than the alternative. (We could avoid this by _not_ syncing the details, or adding some special-case logic, but... meh.)
      omit: ["resource"],
    },
  }
);
